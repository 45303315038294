import './App.css'
import { Toaster } from './components/ui/toaster';
import * as Sentry from "@sentry/react";
import { ComponentError } from '@/views/errors/ComponentError';
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { AppRoutes } from '@/routes';
import { TIME_IN_MILLISECONDS } from './constants';

function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: TIME_IN_MILLISECONDS.HOUR, // def 0
        gcTime: TIME_IN_MILLISECONDS.HOUR + 5 * TIME_IN_MILLISECONDS.MINUTE, // def 5 min
        refetchOnWindowFocus: false, // def true
        retry: false // def 3
      }
    }   
  });

  return (
    <Sentry.ErrorBoundary fallback={ComponentError}>
      <QueryClientProvider client={queryClient}>
        <Toaster />
        <AppRoutes />
      </QueryClientProvider>
    </Sentry.ErrorBoundary>
  )
}

export default App
