export const STORAGE_KEYS = {
  ACCESS_TOKEN: "accessToken",
  STORAGE_RESET_KEY: "storage_reset_key",
  REF_CODE: "refCode",
  DRAFT: "DRAFT",
  CURRENT_INVOICE_GUID: "CURRENT_INVOICE_GUID",
  CURRENT_PROFILE_TAB: "currentTab",
};

export const API_VER = {
  V1: "v1",
};

export const ERR_TYPES = {
  UNAUTHORIZED: "Login required",
  CLAIMED: "Already claimed",
};

export const INVOICE_STATUS = {
  CLAIMED: "CLAIMED",
  PENDING: "PEND-CLAIM",
};

export const QUERY_KEYS = {
  ACCOUNT: "account",
  INVOICES: "invoices",
};

export const TIME_IN_MILLISECONDS = {
  SECOND: 1000,
  MINUTE: 60 * 1000,
  HOUR: 60 * 60 * 1000,
  DAY: 24 * 60 * 60 * 1000
};

export const CUSTOMER_TYPE = {
  PUBLIC: 'PUB'
};

export const SUCCESSFUL_PAGES = {
  REGISTER: 'Register',
  FORGOT_PASSWORD: 'Forgot Password',
  RESET_PASSWORD: 'Reset Password',
  ACTIVATE_ACCOUNT: 'Activate Account',
  SET_PASSWORD: 'Set Password'
};

export const ID_TYPES = [
  {
    id: 'TIN',
    name: 'Tax Identification No'
  },
  {
    id: 'NRIC',
    name: 'Identification Card No'
  },
  {
    id: 'BRN',
    name: 'Business Registration No'
  }
];

export const STATE_CODES = [
  {
    code: '01',
    name: 'Johor'
  },
  {
    code: '02',
    name: 'Kedah'
  },
  {
    code: '03',
    name: 'Kelantan'
  },
  {
    code: '04',
    name: 'Melaka'
  },
  {
    code: '05',
    name: 'Negeri Sembilan'
  },
  {
    code: '06',
    name: 'Pahang'
  },{
    code: '07',
    name: 'Pulau Pinang'
  },
  {
    code: '08',
    name: 'Perak'
  },
  {
    code: '09',
    name: 'Perlis'
  },
  {
    code: '10',
    name: 'Selangor'
  },
  {
    code: '11',
    name: 'Terengganu'
  },
  {
    code: '12',
    name: 'Sabah'
  },
  {
    code: '13',
    name: 'Sarawak'
  },
  {
    code: '14',
    name: 'Wilayah Persekutuan Kuala Lumpur'
  },
  {
    code: '15',
    name: 'Wilayah Persekutuan Labuan'
  },
  {
    code: '16',
    name: 'Wilayah Persekutuan Putrajaya'
  }
];